import axios from "axios";

export default {
    getCategories: state => {
        return state.categories;
    },
    getProducts: state => {
        return state.products;
    },
    getStart: state => {
        return state.start;
    },
    getStartSearch: state => {
        return state.startSearch;
    },
    getTotalCount: state => {
        return state.totalCount;
    },
    getTotalCountSearch: state => {
        return state.totalCountSearch;
    },
    getFilter: state => {
        return state.filter;
    },
    getOpened : state => {
        return state.opened;//per status del cerca
    },
    getQuery: state => {
        return state.query;
    },
    getCatFilter: state => {
        return state.catFilter;
    },
    getCatName: state => {
        return state.catName;
    },
    getIdOptsFilter: state => {
        return state.idOptsFilter;
    },
    getValuesFilter: state => {
        return state.valuesFilter;
    },
    getProdsByCategory: state => {
        return state.prodsByCategory;
    },
    getCartTotalProducts: (state) => {
        return state.totalProdsCart;
    },
    getFavTotalProducts: (state) => {
        return state.favTotalProducts;
    },
    getFavProducts: (state) => {
        return state.favProducts;
    },
    getAuth: (state) => {
        return state.auth;
    },
    getUser: (state) => {
        return state.user;
    },
    getUserCountry(state){
        return state.user.country;
    },
    getStatusSidebar: (state) => {
        return state.openedSidebar;
    },
    manageAuth: async (state) => {
        let token = localStorage.getItem("token");
        let expires = localStorage.getItem("expires");
        let refreshtoken = localStorage.getItem("refreshtoken");
        if (token && expires && token.length > 0 && expires > Math.floor(Date.now() / 1000)) {
            state.auth = true;
        } else if (refreshtoken) {
            //token scaduto
            await axios.get("refreshtoken?refreshtoken=" + refreshtoken).then(resp => {
                localStorage.setItem('expires', Math.floor(Date.now() / 1000) + resp.data.expires_in);
                localStorage.setItem("token", resp.data.token);
                localStorage.setItem("refreshtoken", resp.data.refreshtoken);
                localStorage.setItem("user", resp.data.user);
                state.auth = true;
            }).catch((error) => {
                localStorage.removeItem("expires");
                localStorage.removeItem("token");
                localStorage.removeItem("refreshtoken");
                localStorage.removeItem("user");
                state.auth = false;
                console.log(error);
            });
        } else {
            localStorage.removeItem("expires");
            localStorage.removeItem("token");
            localStorage.removeItem("refreshtoken");
            localStorage.removeItem("user");
            state.auth = false;
        }
    }

}