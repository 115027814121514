export default {
    showSpinner(state) {
        state.spinner = true;
    },
    hideSpinner(state) {
        state.spinner = false;
    },
    setStart(state, value){
        state.start = value;
    },
    setStartSearch(state, value){
        state.startSearch = value;
    },
    setProducts(state, prods) {
        state.products = [...state.products, ...prods]
    },
    setTotalCount(state, totalCount) {
        state.totalCount = totalCount;
    },
    setTotalCountSearch(state, totalCount) {
        state.totalCountSearch = totalCount;
    },
    setCategories(state, cats) {
        state.categories = cats;
    },
    emptyProducts(state) {
        state.products = [];
        state.start = 0;
    },
    setQuery(state, query) {
        state.query = query;
    },
    setCatFilter(state, category) {
        state.catFilter = category;
    },
    setIdOptsFilter(state, idopts) {
        state.idOptsFilter = idopts;
    },
    setValuesFilter(state, filters) {
        state.valuesFilter = filters;
    },
    SET_CART_ID(state, cart) {
        localStorage.setItem('cartIdLaMora', cart.id);
    },
    SET_TOTAL_PRODSCART(state, num) {
        state.totalProdsCart = num;
    },
    setTotalFavProds(state, num) {
        state.favTotalProducts = num;
    },
    SET_AUTH(state, auth) {
        state.auth = auth;
    },
    SET_USER(state, user){
        state.user = user;
    },
    SET_CATNAME(state, catName) {
        state.catName = catName;
    },
    setOpened(state, value){
        state.opened = value;
    },
    setOpenedSidebar(state, value){
        state.openedSidebar = value;
    },
    //Mutations CMS
    SET_MENUS(state, data){
        state.menus = data;
    },
    //serve per prendere gli hooks e settare l'immagine della home
    SET_PAGEDATA(state, data){
        console.log("data",data);
        state.pageData = data;
    },
}