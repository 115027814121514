import Vuex from "vuex";
import getters from '@/services/getters';
import mutations from '@/services/mutations';
import actions from '@/services/actions';

export default new Vuex.Store({
    state: {
        auth : false,
        user : [],
        spinner: true,
        start: 0,//indice scroll infinito ShopContent
        startSearch : 0,//indice scroll infinito SearchContent
        products: [],
        categories: [],
        totalCount: 0,//conta prodotti scroll infinito
        totalCountSearch : 0,//conta prodotti scroll infinito per il cerca
        query: null,
        catFilter: null,
        idOptsFilter: [],
        valuesFilter: [],
        totalProdsCart: 0,
        cart: [],
        totals: [],
        favTotalProducts: 0,
        favorites: Boolean,
        tocart: Boolean,
        token: null,
        refreshtoken: null,
        expiretoken: null,
        opened: false,//variabile per aprire il cerca
        openedSidebar: false,
        catName: "",
        //State CMS
        menus: [],
        pageData: []
    },
    getters,
    mutations,
    actions,
    modules: {}
});
