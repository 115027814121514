<template>
  <div class="search-box mt-4">
    <AutocompleteSearch id="ac-search"/>
  </div>
</template>
<script>
import AutocompleteSearch from "@/components/AutocompleteSearch";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Search",
  components: {
    AutocompleteSearch
  },
  methods: {

  }
}
</script>

<style scoped>

.search-box {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
}

input {
  width: 100%;
  height: 30px;
  margin: 10px 20%;
  border: 1px solid black;
  background: #fff;
}


</style>