<template>
  <section>
    <div class="container">
      <div class="row">
        <!-- taglie da mobile -->
        <!-- CATEGORIE se sono in "nuovi arrivi"-->
        <div class="col-12 mt-3 taglieMobile">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="d-inline-flex w-100 p-2 border-category">
                <button v-on:click="toggleSubMenu()" class="cat-button" id="headingOne" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">CATEGORIE</button>
                <img v-on:click="toggleSubMenu()" style="fill: black; width: 15px" src="/svg/angle-right-solid.svg" alt="" id="catsm" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              </div>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-unstyled p-0 m-0">
                    <li class="mb-2">
                      <router-link v-bind:class="{'active' : this.$route.fullPath === '/shop'}" class="link_filter" :to="'/shop'">
                        VETRINA
                      </router-link>
                    </li>
                    <template v-bind:key="cat.id" v-for="cat in this.categories">
                      <li class="mb-2">
                        <template v-if="cat.description !== 'NUOVI ARRIVI' && cat.description.toUpperCase() !== 'OUTLET AUTUNNO/INVERNO' && cat.description !== 'SCARPE' && cat.description !== 'ACCESSORI' && cat.description !== 'BUONI REGALO' && cat.description !== 'OUTLET autunno/inverno' && cat.description !== 'OUTLET primavera/estate'">
                          <router-link v-bind:class="{'active' : $store.getters.getCatFilter === slugify(cat.description)}" class="link_filter" :to="'/categoria-prodotto/' + slugify(cat.description) + '/'">
                            {{ cat.description }}
                          </router-link>
                        </template>
                        <template v-else-if="cat.description.toUpperCase() === 'OUTLET AUTUNNO/INVERNO'">
                            <router-link v-bind:class="{'active' : this.$route.fullPath === '/categoria-prodotto/outlet-autunnoinverno/'}" class="link_filter" :to="'/categoria-prodotto/outlet-autunnoinverno/'">
                              {{ cat.description }}
                            </router-link>
                        </template>
                        <template v-else-if="cat.description.toUpperCase() === 'OUTLET primavera/estate'">
                            <router-link v-bind:class="{'active' : this.$route.fullPath === '/outlet-pe/'}" class="link_filter" :to="'/outlet-pe/'">
                              {{ cat.description }}
                            </router-link>
                        </template>
                      </li>
                    </template>
                    <!--<li class="mb-2">
                      <router-link class="link_filter" :to="'/tag-prodotto/saldi'">
                        SALDI
                      </router-link>
                    </li>-->
                  </ul>
                  <!--<router-link class="link_filter" :to="'/categoria-prodotto/outlet-autunnoinverno'">
                    OUTLET AUTUNNO/INVERNO
                  </router-link>
                  <br>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12 col-7 pr-0">
          <!--<p v-if="catName === 'OUTLET primavera/estate'" class="title text-left mb-0">OUTLET PR/ES</p>-->
          <p v-if="this.$route.fullPath === '/shop'" class="title text-left mb-0">VETRINA</p>
          <p v-else class="title text-left mb-0">{{ catName }}</p>
        </div>
        <!-- Taglie mobile -->
        <div class="col-lg-6 col-5 pl-0 my-auto taglieMobile">
          <div class="dropdown text-right">
            <button class="button-filter dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">FILTRO TAGLIE</button>
            <img class="restore-filter" v-on:click="restore" v-if="values.length && idOptions.length" src="/svg/xmark-solid.svg" alt="">
            <ul class="dropdown-menu" id="body-dropdown" aria-labelledby="dropdownMenuButton1">
              <template v-for="size in sizes">
                <!-- taglie scarpe -->
                <template v-if="this.slugCat === 'scarpe' && size.description === 'TAGLIA SCARPE'">
                  <p class="filter-voice mt-3 mb-3"> {{ size.description }} </p>
                  <li v-for="sz in size.values" class="d-inline-block">
                    <button class="link_filter" v-on:click="setFilter(size.idOption, sz)"
                            v-bind:class="{'active' : values.indexOf(sz) >= 0 && idOptions.indexOf(size.idOption) >= 0}">
                      {{ sz }}
                    </button>
                  </li>
                </template>
                <!-- taglie accessori -->
                <template v-else-if="this.slugCat === 'accessori' && size.description === 'TAGLIA'">
                  <p class="filter-voice mt-2 mb-2">{{ size.description }}</p>
                  <li v-for="sz in size.values" class="d-inline-block">
                    <button class="link_filter" v-on:click="setFilter(size.idOption, sz)"
                            v-bind:class="{'active' : values.indexOf(sz) >= 0 && idOptions.indexOf(size.idOption) >= 0}">
                      {{ sz }}
                    </button>
                  </li>
                </template>
                <!-- abbigliamento -->
                <template v-else-if="this.slugCat !== 'accessori' && this.slugCat !== 'scarpe' && size.description !== 'TAGLIA SCARPE'">
                  <p class="filter-voice mt-2 mb-2"> {{ size.description }} </p>
                  <li v-for="sz in size.values" class="d-inline-block">
                    <button class="link_filter" v-on:click="setFilter(size.idOption, sz)"
                            v-bind:class="{'active' : values.indexOf(sz) >= 0 && idOptions.indexOf(size.idOption) >= 0}">
                      {{ sz }}
                    </button>
                  </li>
                </template>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <!-- taglie web -->
      <div class="row">
        <div class="col-lg-3 mb-3">
          <button v-if="idOptions.length && values.length" class="primary-btn taglieWebsite" type="button" v-on:click="restore()">
            RIMUOVI FILTRI
          </button>
          <div class="shop__sidebar__categories">
            <p class="filter-voice">CATEGORIE</p>
            <ul>
              <li style="margin-left: 0px">
                <router-link class="link_filter" :to="'/shop'" v-bind:class="{'active' : this.$route.fullPath === '/shop'}">
                  VETRINA
                </router-link>
              </li>
              <li style="margin-left: 0px" v-for="category in categories">
                <template v-if="category.description !== 'ACCESSORI' && category.description.toUpperCase() !== 'OUTLET AUTUNNO/INVERNO' && category.description !== 'SCARPE' && category.description !== 'GIF REGALO' && category.description !== 'NUOVI ARRIVI' && category.description !== 'OUTLET primavera/estate'">
                  <router-link class="link_filter" :to="'/categoria-prodotto/' + slugify(category.description) + '/'" v-bind:class="{'active' : $store.getters.getCatFilter === slugify(category.description)}">
                    {{ category.description }}
                  </router-link>
                </template>
                <template v-else-if="category.description.toUpperCase() === 'OUTLET AUTUNNO/INVERNO'">
                  <li style="margin-left: 0px">
                    <router-link v-bind:class="{'active' : this.$route.fullPath === '/categoria-prodotto/outlet-autunnoinverno/'}" class="link_filter" :to="'/categoria-prodotto/outlet-autunnoinverno/'">
                      {{ category.description }}
                    </router-link>
                  </li>
                </template>
                <template v-else-if="category.description.toUpperCase() === 'OUTLET PRIMAVERA/ESTATE'">
                  <li style="margin-left: 0px">
                    <router-link v-bind:class="{'active' : this.$route.fullPath === '/outlet-pe/'}" class="link_filter" :to="'/outlet-pe/'">
                      {{ category.description }}
                    </router-link>
                  </li>
                </template>
              </li>
              <!--<li style="margin-left: 0px">
                <router-link class="link_filter" :to="'/tag-prodotto/saldi'">
                  SALDI
                </router-link>
              </li>-->
            </ul>
            <!--<router-link class="link_filter" :to="'/categoria-prodotto/outlet-primaveraestate/'" v-bind:class="{'active' : $store.getters.getCatFilter === slugify('outlet-primaveraestate')}">
              OUTLET PRIMAVERA/ESTATE
            </router-link>-->
          </div>
          <div class="taglieWebsite">
            <template v-for="size in sizes">
              <!-- taglie scarpe -->
              <template v-if="this.slugCat === 'scarpe' && size.description === 'TAGLIA SCARPE'">
                <p class="filter-voice mt-3 mb-3">{{size.description}}</p>
                <li v-for="sz in size.values" class="d-inline-block">
                  <button class="link_filter" v-on:click="setFilter(size.idOption, sz)"
                          v-bind:class="{'active' : values.indexOf(sz) >= 0 && idOptions.indexOf(size.idOption) >= 0}">
                    {{ sz }}
                  </button>
                </li>
              </template>
              <!-- taglie accessori -->
              <template v-else-if="this.slugCat === 'accessori' && size.description === 'TAGLIA'">
                <p class="filter-voice mt-2 mb-2">{{size.description}}</p>
                <li v-for="sz in size.values" class="d-inline-block">
                  <button class="link_filter" v-on:click="setFilter(size.idOption, sz)"
                          v-bind:class="{'active' : values.indexOf(sz) >= 0 && idOptions.indexOf(size.idOption) >= 0}">
                    {{ sz }}
                  </button>
                </li>
              </template>
              <!-- abbigliamento -->
              <template v-else-if="this.slugCat !== 'accessori' && this.slugCat !== 'scarpe' && size.description !== 'TAGLIA SCARPE'">
                <p class="filter-voice mt-2 mb-2">{{ size.description }}</p>
                <li v-for="sz in size.values" class="d-inline-block">
                  <button class="link_filter" v-on:click="setFilter(size.idOption, sz)"
                          v-bind:class="{'active' : values.indexOf(sz) >= 0 && idOptions.indexOf(size.idOption) >= 0}">
                    {{ sz }}
                  </button>
                </li>
              </template>
            </template>
          </div>
        </div>
        <div class="col-lg-9">
          <div v-if="$route.query.search" class="search-container">
            <p class="search ">RISULTATI PER: {{ $route.query.search }}</p>
            <!--<button class="primary-btn btn-back" v-on:click="removeSearch()">ELIMINA RICERCA</button>-->
          </div>
          <!-- mostra tutti i prodotti -->
          <div class="row">
            <template v-if="this.products.length > 0">
              <div style="position: relative;" class="col-lg-4 col-sm-4 col-6" v-for="product in products"
                   :key="product.id">
                <SingleProduct :product="product"/>
                <div class="outofstock" v-if="!product.instock">
                  Articolo terminato
                </div>
              </div>
              <template v-if="this.loading">
                <div class="col-lg-12 text-center">
                  <div class="spinner-border text-dark spinner mt-5 mb-5" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="this.products.length === 0">
              <div class="col-lg-12">
                <p class="mt-3 mb-3 text-center text-bigger">Non ci sono prodotti.</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import SingleProduct from "@/components/SingleProduct";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ShopContent",
  data() {
    return {
      loading: true, //per mostrare o meno lo spinner di caricamento dei prodotti
      sizes: [],
      rotateIcon: false,
      outOfStockButton: false,
      search: null,
      tags : this.$route.params.tagslug ? [this.$route.params.tagslug] : [],
      slugCat : this.$route.params.catslug ? this.$route.params.catslug : null,
      prevRoute: null
    }
  },
  components: {
    SingleProduct
  },
  computed: {
    catName() {
      return this.$store.getters.getCatName;
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    products() {
      return this.$store.getters.getProducts;
    },
    totalCount() {
      return this.$store.getters.getTotalCount;
    },
    values() {
      return this.$store.getters.getValuesFilter; //per taglia
    },
    idOptions() {
      return this.$store.getters.getIdOptsFilter; //per taglia
    },
    start() {
      return this.$store.getters.getStart;
    },
    query() {
      return this.$store.getters.getQuery;
    }
  },
  created () {
    this.prevRoute = this.$router.options.history.state.forward;
  },
  beforeMount() {
    let fullPath = this.$route.fullPath;
    if(fullPath === '/shop') {
      this.tags.push("nuovo");
    }else if(fullPath === '/outlet-ai/'){
      this.tags.push("outlet-ai");
    }else if(fullPath === '/outlet-pe/'){
      this.tags.push("outlet-pe");
    }
    this.getSizes();//devo farlo sempre
    if(this.products.length === 0 || this.prevRoute === null ||
        (this.prevRoute && !this.prevRoute.includes("/prodotto/"))){//this.prevRoute !== 'ProductDetails') {
      this.getProducts();
    }else{
      window.addEventListener("scroll", this.listener);
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.listener);
  },
  methods: {
    slugify(text) {
      return text.toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase();
    },
    setFilter(idOption, value) {
      let options = this.idOptions;
      let values = this.values;
      let checkOption = options.indexOf(idOption);
      let checkValues = values.indexOf(value);
      if (checkOption >= 0 && checkValues >= 0) {
        //tolgo l'elemento che già esiste negli array
        options.splice(checkOption, 1);
        values.splice(checkValues, 1);
      }else{
        //aggiungo gli elementi negli array
        options.push(idOption);
        values.push(value);
      }
      this.$store.commit("setIdOptsFilter", options);
      this.$store.commit("setValuesFilter", values);
      this.getProducts();
    },
    toggleSubMenu() {
      let catsm = document.getElementById("catsm");
        if(!catsm.classList.contains("icon_shop_rotate")) {
          catsm.classList.remove("icon_shop_derotate");
          catsm.classList.add("icon_shop_rotate");
          this.shop = true;
        } else if (catsm.classList.contains("icon_shop_rotate")) {
          catsm.classList.remove("icon_shop_rotate");
          catsm.classList.add("icon_shop_derotate");
          this.shop = false;
        }
    },
    async getProducts() {
      this.loading = true;
      this.$store.commit("showSpinner");
      this.$store.commit("emptyProducts");
      this.$store.commit("setCatFilter", this.slugCat);
      this.$store.dispatch("getProducts", {
        search : false,
        start: this.start,
        idOptions: this.idOptions,
        values: this.values,
        slugCategory: this.slugCat,
        description: null,
        tags: this.tags,
      }).then(() => {
        if (this.totalCount > 0) {
          window.addEventListener("scroll", this.listener);
          if (this.totalCount === this.products.length) {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },
    restore() {
      this.$store.commit("setIdOptsFilter", []);
      this.$store.commit("setValuesFilter", []);
      this.$store.commit("setCatFilter", null);
      this.getProducts();
    },
    async getSizes() {
      let szs = await axios.get("sizes");
      this.sizes = szs.data;
    },
    listener() {
      let scrollTop = document.documentElement.scrollTop;//number of pixels that an element's content is scrolled vertically
      let scrollHeight = document.documentElement.scrollHeight;//height of an element's content
      let clientHeight = document.documentElement.clientHeight;//the inner height of an element in pixels
      //150
      if (scrollTop + clientHeight >= scrollHeight - 400) {
        if (this.totalCount === this.products.length) {
          this.loading = false;
        } else {
          window.removeEventListener("scroll", this.listener);
          this.$store.dispatch("getProducts", {
            search : false,
            start: this.start,
            slugCategory: this.$route.fullPath !== '/tag-prodotto/nuovi-arrivi' ? this.slugCat : null,
            idOptions: this.idOptions,
            values: this.values,
            description: null,
            tags: this.tags,
          }).then(() => {
            this.loading = true;
            window.addEventListener("scroll", this.listener);
          });
        }
      }
    }
  }
}
</script>

<style scoped>
.accordion-item{
  border: none;
}
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-back{
  width: 240px !important;
  text-align: center;
  font-size: 15px;
}
.search{
  font-size: 20px;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
}
.icon_shop_rotate { /* non cancellare */
  transition-duration: 0.3s;
  transform: rotate(90deg);
}

.icon_shop_derotate { /* non cancellare */
  transition-duration: 0.3s;
  transform: rotate(0deg);
}

.dropdown-menu {
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
}

.link_filter {
  border: none;
  display: block;
  color: #b7b7b7;
  background-color: white;
  text-decoration: none;
}

.link_filter:hover {
  color: #000;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.cat-button {
  width: 100%;
  color: black;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: white;
  border: none !important;
  text-align: left;
}

.primary-btn {
  width: 100%;
  margin-bottom: 15px;
}

.outofstock {
  color: white;
  font-family: oswald;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  padding: 20px;
  border: 1px solid #fff;
  transform: translate(-50%, -50%);
}

.active {
  color: black;
  font-weight: bold;
}

:global(.dropdown-toggle:after) {
  display: none;
}

.filter-voice {
  font-weight: 600;
  color: #000;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: left;
}


@media only screen and (max-width: 967px) {

  .button-filter {
    color: black;
    background-color: white;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 17px;
    text-transform: uppercase;
    font-family: oswald;
  }

  .restore-filter {
    width:15px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .shop__sidebar__categories {
    visibility: hidden;
    display: none;
  }

  .shop__sidebar__categories ul li a, .shop__sidebar__categories ul a {
    font-size: 15px;
    margin-left: 0;
  }

  .taglieWebsite {
    visibility: hidden;
    display: none;
  }

  #body-dropdown {
    width: 300px !important;
    padding: 10px 20px;
  }

  ul li {
    margin-left: 0;
  }
}

/*taglie da websiste*/
@media (min-width: 968px) {
  .taglieMobile {
    visibility: hidden;
    display: none;
  }

}

.border-category{
  border: 1px solid #000;
  border-radius: 10px;
}

.border-category button{
  font-family: oswald;
}

.button-filter{
  border-radius: 10px;
}

.accordion-body{
  padding: 10px;
}

.shop__sidebar__categories ul{
  margin: 0;
  padding: 0;
}

.accordion-body li{
  margin: 0;
}

</style>