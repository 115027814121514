import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

export default {
    async getProducts({commit}, payload) {
        let url = (payload.idOptions.length && payload.values.length) || payload.slugCategory || payload.description ? "products/filter" : "products";
        //alert(payload.startSearch + " " + payload.search)
        await axios.post(url, {
            "start": payload.search ? payload.startSearch : payload.start,
            "slugCategory": payload.slugCategory,
            "limit": 12,
            "idOptions": payload.idOptions,
            "values": payload.values,
            //"excludeCats": payload.excludeCats,
            "description": payload.description,
            "type": "website",
            "tags": payload.tags,
        }).then((prods) => {
            commit('hideSpinner');
            commit('setProducts', prods.data.products);
            commit('SET_CATNAME', prods.data.catName);
            if(payload.search) {
                commit('setStartSearch', payload.startSearch + 12);
                commit('setTotalCountSearch', prods.data.totalCount);
            }else{
                commit('setStart', payload.start + 12);
                commit('setTotalCount', prods.data.totalCount);
            }
        }).catch(error => {
            commit('hideSpinner');
            console.log(error);
        });
    },
    async getCategories({commit}) {
        await axios.get("categories?start=0&limit=100&type=website").then((cats) => {
            commit('setCategories', cats.data.categories);
        }).catch(error => {
            commit('hideSpinner');
            console.log(error);
        });
    },
    async logout({commit}) {
        let token = localStorage.getItem("token");
        await axios.get('logout', {
            headers: {Authorization: `Bearer ${token}`}
        }).then(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshtoken');
            localStorage.removeItem("expires");
            localStorage.removeItem("user");
            commit("SET_USER", []);
            commit("SET_AUTH", false);
            router.push('/');
        }).catch(error => {
            console.log(error);
        });
    },
    async addToCart({commit}, cart) {
        commit('showSpinner');
        let idcart = localStorage.getItem("cartIdLaMora");
        let config = {};
        let token = localStorage.getItem("token");
        if (token) {
            idcart = null;
            config = {
                headers: {Authorization: `Bearer ${token}`}
            };
        }
        await axios.post("addtocart", {
            idcart: idcart,
            idProduct: cart.idProduct,
            idVariant: cart.idVariant,
            qnt: cart.qnt
        }, config).then((res) => {
            commit('SET_CART_ID', res.data.cart);
            commit('SET_TOTAL_PRODSCART', res.data.totalProdsCart);
            commit('hideSpinner');
            Swal.fire({
                showCancelButton: true,
                title: 'Prodotto aggiunto al carrello',
                icon: 'success',
                confirmButtonText: 'Vai al carrello',
                cancelButtonText: "Continua lo shopping",
                confirmButtonColor: '#816253',
                cancelButtonColor: '#2b2b2b'
            }).then((result) => {
                if (result.isConfirmed) {
                    router.push('/cart');
                } else if (result.isDismissed) {
                    router.push(router.options.history.state.back);
                }
            });
        }).catch(error => {
            commit('hideSpinner');
            console.log(error);
        });
    },
    emptyFavProducts({commit}) {
        commit('emptyFavProducts');
    },
    async getProfileData({commit}){
        let token = localStorage.getItem("token");
        await axios.get("user",
            {
                headers: {Authorization: `Bearer ${token}`},
            }).then((resp) => {
            localStorage.setItem("user", JSON.stringify(resp.data));
            commit("SET_USER",resp.data);
            commit("hideSpinner");
        }).catch((error) => {
            commit("hideSpinner");
            if (error.response.status === 400) {
                Swal.fire({
                    title: error.response.data.error
                        ? error.response.data.error
                        : "Errore. Riprovare più tardi o ricontrollare i dati immessi.",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#674e42",
                });
                console.log(error);
            } else {
                console.log(error);
            }
        });
    },
    //CMS
    async fetchMenus({commit}) {
        try {
            const response = await axios.get(
                "https://app.lamoraglamour.com/api/cms/v1/menus",
            );
            commit("SET_MENUS", response.data.menus)
            //this.menus = response.data.menus;
        } catch (error) {
            console.error("Error fetching menus:", error);
        }
    },
    //serve per prendere gli hooks e settare l'immagine della home
    async components({ commit }, isLoadingImage) {
        try {
            const response = await axios.get("https://app.lamoraglamour.com/api/cms/v1/components");
            // Filtra per componenti senza pagine associate
            commit("SET_PAGEDATA", response.data.components.filter(component => !component.title && !component.slug));
            commit("SET_LOADING_IMAGE", isLoadingImage); // Imposta isLoadingImage a false l'immagine è stata caricata
        } catch (error) {
            console.error(error);
        }
    },
    //CMS
}