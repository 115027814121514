import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import Blog from "@/views/Blog";
import ProductDetails from "@/views/ProductDetails";
import Cart from "@/views/Cart";
import Checkout from "@/views/Checkout";
import ShippingAndReturns from "@/views/ShippingAndReturns";
import Login from "@/views/Login";
import Shop from "@/views/Shop";
import Message from "@/views/Message";
import SignUp from "@/views/SignUp";
import Favourites from "@/views/Favourites";
import Profile from "@/views/Profile";
import TermsAndConditions from "@/views/TermsAndConditions";
import OrderSummary from "@/views/OrderSummary";
import Payment from "@/views/Payment";
import RecoverPassword from "@/views/RecoverPassword";
import ShopContent from "@/components/ShopContent";
import SearchContent from "@/components/SearchContent";
import ComeAcquistare from "@/views/ComeAcquistare";
import FilteredPosts from "@/views/FilteredPosts";
import SinglePost from "@/views/SinglePost";

const routes = [
   /* {
        path: '/',
        name: 'Home',
        component: Home,
        meta : {
            auth : false,
            title : "La Mora Glamour | 100% Casual Chic",
            description : "Il primo brand in Italia a produrre uno stile di abbigliamento tutto centrato SOLO ed ESCLUSIVAMENTE nello stile CASUAL CHIC"
        }
    },*/
    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
        meta : {
            auth : false,
            title : "Blog | La Mora Glamour",
            description : "Il blog dell'abbigliamento tutto centrato SOLO ed ESCLUSIVAMENTE nello stile CASUAL CHIC"
        }
    },
    {
        path: '/commerce',
        name: 'Shop',
        component: Shop,
        meta : {
            auth : false,
            title : "Shop | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path: '/shop',
        name: 'Clothing',
        component: ShopContent,
        meta : {
            auth : false,
            title : "Abbigliamento | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchContent,
        meta : {
            auth : false,
            title : "Risultati della ricerca | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path : '/outlet-ai',
        name: 'OutletAI',
        component: ShopContent,
        meta : {
            auth : false,
            title : "Outlet Autunno Inverno | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path : '/outlet-pe',
        name: 'OutletPE',
        component: ShopContent,
        meta : {
            auth : false,
            title : "Outlet Primavera Estate | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path: '/categoria-prodotto/:catslug',
        name: 'CatProdotto',
        component: ShopContent,
        meta : {
            auth : false,
            title : "Categoria | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path: '/tag-prodotto/:tagslug',
        name: 'TagProdotto',
        component: ShopContent,
        meta : {
            auth : false,
            title : "Nuovi arrivi | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
    {
        path: '/taglia-prodotto/:sz',
        name: 'SizeProdotto',
        component: Shop,
        meta : {
            auth : false,
            title : "Taglia | La Mora Glamour",
            description : "",
            hideHeader: true,
        }
    },
  /*  {
        path: '/chisiamo',
        name: 'Chisiamo',
        component: Chisiamo,
        meta : {
            auth : false,
            title : "Chi Siamo | La Mora Glamour",
            description : ""
        }
    },*/
    {
        path: '/comeacquistare',
        name: 'ComeAcquistare',
        component: ComeAcquistare,
        meta : {
            auth : false,
            title : "Come acquistare | La Mora Glamour",
            description : ""
        }
    },
   /* {
        path: '/fisicita',
        name: 'Fisicita',
        component: Fisicita,
        meta : {
            auth : false,
            title : "Fisicità | La Mora Glamour",
            description : ""
        }
    },*/
   /* {
        path: '/fisicita/fisico-mela',
        name: 'FisicoMela',
        component: FisicoMela,
        meta : {
            auth : false,
            title : "Fisico Mela | La Mora Glamour",
            description : ""
        }
    },*/
   /* {
        path: '/fisicita/fisico-pera',
        name: 'FisicoPera',
        component: FisicoPera,
        meta : {
            auth : false,
            title : "Fisico Pera | La Mora Glamour",
            description : ""
        }
    },*/
    /*{
        path: '/fisicita/fisico-petit',
        name: 'FisicoPetit',
        component: FisicoPetit,
        meta : {
            auth : false,
            title : "Fisico Petit | La Mora Glamour",
            description : ""
        }
    },*/
    {
        path: '/prodotto/:param',
        alias: '/prodotto/:param/:size',
        name: 'ProductDetails',
        component: ProductDetails,
        meta : {
            auth : false,
            title : "Prodotto | La Mora Glamour",
            description : ""
        }
    },
    /*{
        path: '/contatti',
        name: 'ContactUs',
        component: ContactUs,
        meta : {
            auth : false,
            title : "Contatti | La Mora Glamour",
            description : ""
        }
    },*/
    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
        meta : {
            auth : false,
            title : "Carrello | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/favourites',
        name: 'Favourites',
        component: Favourites,
        meta : {
            auth : false,
            title : "Preferiti | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta : {
            auth : true,
            title : "Checkout | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta : {
            auth : false,
            title : "Checkout | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/payment/:idorder',
        name: 'Payment',
        component: Payment,
        meta : {
            auth : false,
            title : "Pagamento | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/message/:status',
        name: 'Message',
        component: Message,
        meta : {
            auth : false,
            title : "Messaggio | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/ordersummary/:idordine',
        name: 'OrderSummary',
        component: OrderSummary,
        meta : {
            auth : true,
            title : "Dettaglio ordine | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta : {
            auth : false,
            title : "Login | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/recover',
        name: 'Recover',
        component: RecoverPassword,
        meta : {
            auth : false,
            title : "Recupera password | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
        meta : {
            auth : false,
            title : "Iscriviti | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta : {
            auth : true,
            title : "Profilo | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/tag/:slug',
        name: 'Tag',
        component: FilteredPosts,
        meta : {
            auth : false,
            title : "Blog | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/category/:slug',
        name: 'Category',
        component: FilteredPosts,
        meta : {
            auth : false,
            title : "Blog | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/post/:id',
        name: 'SinglePost',
        component: SinglePost,
        meta : {
            auth : false,
            title : "Blog | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/shippingandreturns',
        name: 'Shipping and Returns',
        component: ShippingAndReturns,
        meta : {
            auth : false,
            title : "Resi | La Mora Glamour",
            description : ""
        }
    },
    {
        path: '/termsandconditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
        meta : {
            auth : false,
            title : "Termini e condizioni | La Mora Glamour",
            description : ""
        }
    },
  /*  {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
        meta : {
            auth : false,
            title : "Not found | La Mora Glamour",
            description : ""
        }
    }*/
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to,from, savedPosition) {
        if (to.name !== "Clothing" && to.name !== "CatProdotto" && to.name !== "TagProdotto" && to.name !== 'SizeProdotto') {
            return {top: 0};
        }else if(savedPosition){
            return savedPosition;
            /*
            return new Promise((resolve) => {
                setTimeout(() => {
                    console.log(savedPosition);
                    resolve(savedPosition);
                }, 200)
            })
             */
        }
    }
});

router.beforeEach((to, from, next) => {
    store.getters.manageAuth;
    if(to.meta.title !== undefined) {
        document.title = to.meta.title;
        document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description);
    }
    if(to.meta.auth && !store.getters.getAuth){
        next('/login');
    } else {
        next();
    }
});

export default router;